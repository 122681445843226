.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

select::-ms-expand {
  display: none;
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        padding: 17px 0;
      }
    }
  }
}
/* Power Review */
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'behulpzaam ';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'niet behulpzaam ';
              visibility: visible;
            }
            &:after {
              display: none;
            }
          }
        }
      }
      .pr-rd-flag-review-container {
        .pr-flag-icon {
          margin-left: -4px;
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-rd-helpful-action {
            margin-bottom: 0px;
          }
          .pr-rd-flag-review-container {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

#pr-war-form {
  .tag-group {
    .input-add-tag {
      width: 87%;
    }
  }
  .pr-huidproblemen-form-group,
  .pr-productvoordelen-form-group {
    .pr-control-label {
      float: left !important;
      margin-right: 20px;
    }
  }
}
