$sidebarWidth: 3em;

@mixin address-overlay-shipping {
  h2 {
    margin-bottom: $sidebarWidth/3;
  }
  .form_element {
    margin-bottom: 0.6em;
  }
}

@mixin shipping-address-info {
  .address-container {
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .address_controls {
    margin-bottom: 10px;
    padding: 0 10px;
    a {
      text-decoration: underline;
    }
  }
  .address_form_container {
    a.selectBox {
      margin-bottom: 20px;
    }
    .fs.address {
      margin: $sidebarWidth/2 0;
    }
  }
  .address-form__title-container {
    width: 60%;
  }
  .right {
    padding-#{$ldirection}: 1.2em;
  }
  .address-note {
    padding: 0.4em 0;
    color: red;
  }
}

.margin_top_bottom {
  margin: 0.8em 0;
}

body.order-confirmation {
  .account-utilities {
    display: none;
  }
  .cart-item:last-child {
    border-bottom: 1px solid #cccccc;
  }
}

.transaction-panel-details {
  margin-top: 2em;
  .trans_detail_item {
    float: left;
    margin-bottom: 2em;
    margin-right: 2em;
    position: relative;
    width: 16.4em;
    word-wrap: break-word;
    .change_link {
      position: absolute;
      top: 0.6em;
      right: 0;
    }
  }
}

.order-totals__section {
  width: 25%;
  float: right;
  td {
    text-align: right;
  }
}

.address-overlay-billing {
  @include address-overlay-shipping;
}

.address-form__content {
  margin-bottom: $sidebarWidth/3;
  .title_container {
    margin-bottom: 0.8em;
    span {
      input {
        display: inline;
        margin: 0 0.4em;
      }
    }
  }
  .phone_label_container,
  .country_container {
    margin-bottom: 0.8em;
  }
  input[type='tel'],
  input[type='text'] {
    width: 100%;
    margin-bottom: 0.8em;
  }
}

.checkout {
  ol.checkout-progress {
    li {
      float: left;
      margin-left: 1.5em;
      width: 12.5em;
      list-style-type: decimal;
      margin-top: 1.5em;
    }
  }
  .samples-buttons,
  .sample-select-checkbox {
    display: block !important;
    visibility: visible;
  }
  .left.checkout__content {
    .checkout-buttons-container {
      margin-bottom: 30px;
      .btn {
        float: right;
      }
    }
    .cart-items {
      .sub__total {
        float: right;
        margin-bottom: 0.8em;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0.8em;
      }
    }
    #checkout_complete {
      .wrapper {
        #payment-info-panel {
          .cc-header {
            display:none;
          }
        }
      }
    }
    .payment-type-panel,
    .confirm-panel {
      margin-bottom: 1em;
      .payment-type {
        margin-right: 0.8em;
        label {
          margin-left: 0.4em;
        }
      }
      .panel_content {
        margin-bottom: 0.8em;
      }
    }
    .checkout__panel-title {
      margin-bottom: 1.5em;
    }
    #viewcart-panel,
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .continue-buttons {
          .disabled.continue-checkout {
            background-color: #cccccc;
          }
        }
      }
    }
    .checkout-buttons-container {
      .checkout-buttons.disabled {
        background-color: #cccccc;
      }
    }
    #billing-address-info-panel {
      margin-bottom: 1.5em;
    }
    #delivery-info-panel {
      h2.shipping-info-panel__title {
        margin-bottom: 0px;
      }
      .shipform_fields p {
        margin: 10px 0px;
      }
    }
    #giftwrap-info-panel {
      fieldset {
        margin: 10px 0px;
      }
    }
    #emailpromo-info-panel {
      margin-top: 0px;
      p {
        margin: 15px 0px;
      }
      .sms_promotions {
        margin-left: 0px;
      }
      .newsletter-info__item {
        margin-left: 0px;
        padding-left: 0px;
      }
    }
  }
  .right.checkout__sidebar {
    .return-link-container {
      margin-bottom: 2em;
    }
    .offer_code_form_container {
      input.form-submit {
        position: relative;
      }
    }
    section {
      margin-bottom: 15px;
    }
    .account-utilities__section {
      background: #eff0f0;
      padding: 16px 20px;
      h3.account-utilities__header {
        font-family: 'Brandon Text Bold';
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        text-indent: 0;
        margin: 4px 0 10px 0;
        padding-bottom: 17px;
        border-bottom: 1px solid #000000;
      }
    }
    .links-panel {
      background-color: #fff;
      border: 4px solid #eff0f0;
      padding: 16px 20px;
      ul li {
        display: block;
        border: none;
        margin-bottom: 10px;
      }
    }
    .offer-code-panel {
      .offer-code-panel__content {
        margin-top: 0px;
        #offer_code {
          p {
            margin: 15px 0px;
          }
          em {
            display: block;
            margin-top: 5px;
          }
          input {
            margin-top: 10px;
          }
        }
      }
    }
  }
  #shipping-page-info-panel {
    overflow: hidden;
    .checkout-buttons-container {
      margin-bottom: 0;
    }
    .shipping-address-info {
      @include shipping-address-info;
    }
    .billing-info {
      margin-bottom: 0.8em;
      @include shipping-address-info;
      input.bill_to_shipping_toggle {
        margin-right: 0.6em;
      }
    }
    .delivery-info {
      #delivery-instructions {
        width: 50%;
      }
      select {
        width: 50%;
      }
      .shipform_fields {
        padding: 0px 10px;
      }
    }
    #emailpromo-info-panel {
      float: left;
      .newsletter-info {
        p,
        fieldset {
          padding: 0px 10px;
        }
      }
    }
    #giftwrap-info-panel {
      fieldset,
      .gift-options-container {
        padding: 0px 10px;
      }
    }
    #checkout_shipping {
      section .sub-block {
        #bill_to_shipping_container,
        #address_form_container,
        #billing_address_form_container {
          padding: 0px 10px;
        }
      }
    }
  }
  .giftwrap-info {
    margin-bottom: $sidebarWidth/2;
    .checkout__subtitle {
      font-size: 1em;
      margin-top: 0.6em;
    }
    .gift-options-wrapper {
      width: 70%;
      .form_element {
        margin-top: 0.7em;
        width: 46%;
        float: left;
        padding-bottom: 0.7em;
      }
      .right {
        padding-left: 0.8em;
      }
      .giftmsg {
        width: 95%;
      }
    }
  }
  address {
    font-style: normal;
  }
  #address_form_container {
    border: 0;
    padding: 0;
  }
  .checkout-header {
    margin: $sidebarWidth/2 0;
  }
}

.shipping .checkout-progress__shipping,
.review .checkout-progress__review,
.billing .checkout-progress__billing,
.confirm .checkout-progress__confirm {
  font-weight: bold;
}

#samples {
  .samples-page {
    #samples-panel {
      .column {
        .samples-buttons.bottom {
          display: block;
        }
      }
      .left.checkout__content {
        .samples {
          .product.sample-cell {
            ul.sample-sku-list {
              li.sample-sku-item {
                .sample-select-checkbox {
                  display: block;
                }
              }
            }
          }
        }
      }
      .content {
        .product-list {
          .product {
            .details {
              min-height: 105px;
            }
          }
        }
      }
    }
  }
}

#recommended-products-panel {
  .recommended-product-items {
    .recommended-item {
      position: relative;
      padding-bottom: 60px;
      .column.item_info {
        position: relative;
        .product_name {
          height: 45px;
        }
        .product_subname {
          height: 20px;
        }
      }
      .add_button {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.sms_promotions {
  margin-bottom: 10px;
  margin-left: 20px;
  .sms_options_container {
    margin: 10px 0;
    .phone_container {
      input {
        margin-top: 5px;
      }
    }
  }
}

.newsletter-info {
  .newsletter-info__fieldset {
    .newsletter-info__text--provid {
      float: left;
      width: 100%;
    }
    .newsletter-info__item {
      width: 100%;
    }
  }
  &__header {
    text-indent: 0;
  }
}

.touch {
  select {
    min-width: 100%;
  }
}

.below-columns {
  .recommended-products-panel {
    .recommended-item {
      .add_button {
        position: relative;
        form input {
          word-wrap: break-word;
          white-space: pre-wrap;
          height: auto;
          line-height: 20px;
          background-color: #000;
        }
        form input:hover {
          background-color: #ff4661;
        }
      }
      .description {
        .color {
          margin-bottom: 14px;
        }
      }
    }
  }
}

.review {
  .checkout__content {
    .checkout-header {
      margin: 3.5em 0;
    }
    #review-instructions {
      padding: 0px 10px;
    }
    .shipping-panel {
      .transaction-panel-details {
        padding: 0px 10px;
        .transaction-item {
          width: 265px;
        }
        .gift_container {
          clear: both;
          zoom: 1;
        }
        .shipping_container {
          clear: none;
        }
      }
    }
  }
}

.billing {
  #review-instructions {
    padding: 0px 10px;
  }
  .payment_method_container {
    p {
      margin: 10px 0px;
      padding: 0px 10px;
    }
    .payment-type-ncc,
    .payment-type-cc {
      padding: 0px 10px;
    }
    .payment-type {
      label {
        display: block;
        overflow: hidden;
        height: 60px;
        padding: 20px 0px;
        img {
          position: absolute;
          margin-top: -7px;
          left: 30px;
        }
      }
      label#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_sofort_label img {
        padding: 0px !important;
      }
      label#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_debit_label img {
        margin-top: -20px;
      }
      label#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label img {
        width: 60px;
      }
      label#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mc_label img {
        margin-top: -20px;
      }
    }
    .payment-type {
      float: none;
      margin-bottom: 10px;
    }
  }
  .shipping-panel {
    .transaction-panel-details {
      padding: 0px 10px;
      .transaction-item {
        width: 265px;
      }
      .gift_container {
        clear: both;
        zoom: 1;
      }
      .shipping_container {
        clear: none;
      }
    }
  }
}

.product.deactivate .sample-select-button {
  background: #d4d4d4;
}

#customer-service.mobile_terms_condition {
  h2.customer-service-header {
    float: none;
    width: auto;
    padding: 0px 0px 10px 0px;
  }
  .customer-service-content {
    margin-top: 15px;
    p {
      margin-bottom: 0px;
    }
  }
}

#order_detail.order-confirmation {
  .order-products .cart-item__desc {
    width: 34%;
  }
}

@media only screen and (max-width: 768px) {
  .viewcart-buttons-panel .continue-buttons {
    width: 50%;
  }
  .checkout {
    .right.checkout__sidebar .links-panel ul li {
      word-wrap: break-word;
    }
  }
  .js-sticky-header.js-sticky-header--animate {
    .js-site-header-sticky {
      .site-utils__inner {
        height: auto;
      }
      .site-utils__logo {
        width: 100%;
        a {
          text-align: center;
        }
      }
      .site-utils__links {
        float: none;
      }
      .site-utils__nav .menu__link--lvl-1 {
        padding: 0 2px;
      }
    }
  }
}

.checkout__subtitle {
  letter-spacing: 0.1em;
}

#viewcart,
#samples,
#shipping,
#review,
#payment,
.checkout {
  h2.checkout__panel-title {
    font-size: 14px;
    font-family: $brandon-text-bold;
  }
  .wp_message {
    color: $color-green;
  }
}

.checkout-co-enabled {
  &#viewcart {
    .pg_wrapper {
      .left {
        .viewcart-header {
          margin: 0;
          .checkout-page-title {
            font-size: 30px;
            margin-bottom: 0;
          }
        }
        .recommended-products-panel {
          .content {
            .recommended-product-items {
              .recommended-item {
                .add_button {
                  .btn {
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
      .right {
        .order-summary-panel {
          &__title {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
