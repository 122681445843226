@mixin site-email-sms-signup-year {
  padding: 0 8px;
  margin: 0;
}
.site-footer {
  ul.site-footer-contact__menu {
    li.site-footer-contact__item--chat a {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
  .site-email-signup__form {
    input.site-email-signup__field {
      width: 50%;
    }
    input.site-email-signup__submit {
      letter-spacing: 2px;
      padding: 0 7px;
    }
  }
}

.section-store-locator {
  .resp-tabs-list {
    li {
      letter-spacing: 0.1em;
    }
  }
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
  #map-canvas {
    .gmnoprint {
      div[title^='Naar links schuiven'],
      div[title^='Naar rechts schuiven'],
      div[title^='Naar boven schuiven'],
      div[title^='Naar beneden schuiven'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.elc-user-state-anonymous {
  .site-email-sms-signup {
    .site-email-sms-signup__header {
      h1 {
        font-size: 2em;
        letter-spacing: 0px;
        height: auto;
      }
      h2 {
        font-size: 1.3em;
        letter-spacing: 0;
      }
    }
    .site-email-sms-signup__agree {
      input#form--email_signup--field--SMSPROMO_SMS_PROMOTIONS {
        display: none;
      }
    }
    .site-email-sms-signup__birthday {
      text-align: left;
      p.site-email-sms-signup__birthday-label {
        width: auto;
        letter-spacing: 0px;
        margin: 0px;
      }
      .site-email-sms-signup__year {
        @include site-email-sms-signup-year;
      }
      .site-email-sms-signup__month,
      .site-email-sms-signup__day {
        @include site-email-sms-signup-year;
        width: 42px;
      }
    }
  }
}

.customer-service-landing-block {
  height: 320px;
  a {
    position: relative;
    bottom: 0px;
  }
}

.js-quickshop-container {
  .quickshop {
    .product__actions a.button--secondary {
      width: 60%;
    }
  }
}

.field-content {
  .search-results__header {
    .search-form {
      .search-form__fields {
        width: 700px;
        .search-form__submit {
          width: 134px;
        }
      }
    }
  }
}

.section-esearch {
  .search-form--results {
    input.search-form__submit {
      letter-spacing: 1.5px;
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 160px;
    }
  }
  #search-wrapper {
    #results-container {
      #col-1 {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
      }
      #col-2 {
        float: none;
        width: 100%;
        #product-results {
          .product-grid__content {
            max-width: 1440px;
            display: flex;
            flex-wrap: wrap;
            .product-grid__item {
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
}

.customer-service-contact-form__country-select.dropdown::-ms-expand {
  display: block;
}

.tiny-waitlist-overlay {
  #waitlist {
    .email_input {
      display: inline-block;
    }
    .align-r {
      display: inline-block;
    }
    .field {
      border-top: 1px solid gray;
      padding-top: 10px;
    }
    p.waitlist_message {
      margin-bottom: 10px;
    }
    .error_messages.error li {
      margin-left: 0px;
    }
    h2 {
      margin: 0 0 15px 0;
    }
  }
}

#colorbox .waitlist-iframe-wrapper {
  height: 95%;
  width: 100%;
}

.makeup-lesson-page__slide {
  .makeup-lesson__step-info {
    .makeup-lesson__step-header {
      .makeup-lesson__step-name {
        padding-top: 15px;
      }
    }
  }
}

.product__notify-me {
  a.product__button {
    width: 100% !important;
  }
}

.makeup-lesson-page__video {
  .bobbis-lessons__container {
    .bobbis-lessons__preview--prev,
    .bobbis-lessons__preview--next {
      display: none;
    }
  }
}

.favorites-page.account__section {
  .favorites-page__content {
    ul.favorites__list {
      a.product-info__link.js-add-to-cart.button {
        padding: 0.5em 0.5em;
      }
    }
  }
}

.sign-in .registration__show-pass {
  margin: 5px 0 0 15px;
}

.customer-service-section {
  @media (max-width: 768px) {
    .customer-service-landing-block {
      a {
        bottom: 25px;
      }
    }
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        a {
          margin-top: 30px;
          letter-spacing: 1px;
        }
      }
    }
  }
}

.skin__foundation__menu.skin__foundation__menu--01 {
  width: 71.333% !important;
  left: -139px !important;
  cursor: context-menu !important;
}

.skin__foundation__menu.skin__foundation__menu--02 {
  display: none !important;
}

.skin__foundation__menu.skin__foundation__menu--03 {
  display: none !important;
}

.lp_radio_button {
  input {
    display: none;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

.js-sticky-header {
  .js-site-header-sticky {
    .site-utils__inner {
      max-width: 90%;
    }
  }
}

#bt_notification_container {
  .content {
    #bt_notification {
      .bt-bottom-right {
        border: 1px solid $color-light-gray;
      }
    }
  }
}

.pg-checkout {
  overflow: hidden;
  .site-header {
    nav {
      &.site-nav--primary {
        display: block;
        .gnav-section {
          margin: 0 15px;
        }
      }
    }
  }
  .site-footer {
    &__wrap {
      padding-bottom: 65px;
      .block-template-sticky-footer-offer-v1 {
        margin-bottom: 0px;
      }
    }
  }
}
