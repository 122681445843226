.return-user__item,
.new-account__item,
.request-form__item,
.sent-info__text,
.password-reset__item {
  margin-bottom: 0.8em;
}

.signin-page {
  max-width: 1024px;
  margin: 40px auto;
}

.account__panel-title {
  font-family: 'Brandon Text Bold';
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-indent: 0;
  margin: 4px 0 10px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #000000;
}

.order-details-page .account-utilities {
  float: left;
  margin-right: 2em;
}

.order-details-page__content,
.reorder-page__content {
  width: auto;
  overflow: hidden;
  padding-bottom: 1px;
}

.password-reset__item {
  .field {
    display: block;
  }
}

.account-page {
  &__content {
    .section-header {
      &:before {
        background: none;
      }
    }
    .account-profile {
      &__newsletter {
        .newsletter_text {
          margin-bottom: 0;
        }
      }
      &__email {
        word-wrap: break-word;
      }
    }
  }
}

.account {
  .profile-page__content {
    #profile_preferences {
      .profile-info {
        .profile-info__item.profile-info__required-container {
          float: none;
          padding: 0;
        }
      }
    }
    .profile-info__fieldset {
      .profile-info__item.title_container {
        float: none;
        width: 100%;
      }
    }
    .newsletter-info__fieldset {
      .newsletter-info__item {
        float: none;
      }
    }
    .profile-info__item {
      .password_help_text {
        padding-top: 5px;
      }
    }
  }
  #address_form_container {
    margin-bottom: 17px;
    .address-overlay-shipping {
      .form_element {
        margin-bottom: 6px;
        select {
          padding: 5px 10px;
        }
      }
      .title_container,
      .phone_label_container {
        margin-bottom: 10px;
      }
      input[type='text'],
      select {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .profile-pic-upload {
    .upload-file {
      &__imposter {
        word-wrap: break-word;
        white-space: normal;
        height: 45px;
      }
    }
  }
}

.optional-info {
  .optional-info__fieldset {
    .optional-info__item {
      float: none;
    }
  }
}

a.selectBox {
  &.error {
    border: 1px solid #ff0000;
  }
}

.order-details-page.signin-page {
  max-width: 1024px;
  margin: 40px auto;
  .account-utilities {
    float: left;
    margin-right: 32px;
  }
  .order-details-page__content {
    width: auto;
    overflow: hidden;
    padding-bottom: 1px;
    .transaction-panel-details {
      margin-top: 32px;
      .trans_detail_item {
        float: left;
        margin: 0 28px 28px 0;
        position: relative;
        width: 225px;
        word-wrap: break-word;
      }
    }
    .order-products {
      .cart-item.product {
        .cart-item__desc {
          @media (min-width: 768px) {
            width: 31%;
            float: left;
          }
        }
        .cart-item__qty {
          @media (min-width: 768px) {
            width: 14%;
            clear: none;
            float: left;
          }
        }
        .cart-item__price {
          @media (min-width: 768px) {
            width: 20%;
          }
        }
        .cart-item__thumb {
          width: 14%;
          padding-right: 0;
        }
        .cart-item__total {
          width: 14%;
          text-align: right;
          float: right;
        }
      }
    }
    .order-totals {
      width: 25%;
      float: right;
      td {
        text-align: right;
      }
    }
  }
}

.past-purchases {
  &__shopping {
    margin: 30px 0 0 0px;
  }
}

.sample {
  .cart-item {
    &__qty-label {
      display: block;
    }
  }
}

#address_form_container {
  .address-overlay-shipping {
    .address {
      .default_ship_container {
        float: left;
        width: 100% !important;
      }
    }
  }
  #address {
    position: relative;
  }
}

.favorites-page {
  .favorites-page__content {
    .my-lists__list-actions {
      a.my-lists__list-link--print,
      a.my-lists__list-link--email {
        float: left;
      }
    }
  }
}

.account #cboxClose {
  top: 5px;
  right: 5px;
}

.password-sent-page {
  .password-sent__text {
    .sent-info__text {
      margin-left: 20px;
    }
  }
}

#registration_short {
  #form--registration_short--field--PASSWORD {
    margin-bottom: 20px;
  }
}

.account-page {
  .account-page__content {
    .account-payment {
      .section-header {
        padding: 0 44px;
      }
    }
  }
}

.password-reset-page {
  &__content {
    .error_messages {
      &.error {
        li {
          margin-left: 0;
        }
      }
    }
  }
}

.password-sent-page {
  &__header {
    text-indent: 0;
    padding-left: 20px;
  }
}
