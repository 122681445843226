.enhanced-delivery-page {
  .pg_wrapper {
    .column.left {
      #checkout_complete {
        .wrapper {
          #payment-info-panel {
            .cc-header,
            #payment-type-ncc {
              border-top: 1px solid $color-nobel;
            }
            #payment-type-ncc {
              border-bottom: 1px solid $color-nobel;
              .payment-type {
                border-bottom: 1px solid $color-nobel;
              }
            }
            #payment-type-cc,
            #payment-type-ncc {
              .group2 {
                .payment-type {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }
      .wrapper {
        #checkout_shipping {
          #billing-address-info-panel #billing_address_form_container,
          .shipping-address-info #address_form_container {
            border-bottom: none;
          }
          .form-check {
            margin-top: 8px;
          }
          #billing-address-info-panel {
            .billing-title {
              margin: 30px 0 17px;
            }
          }
        }
        .standard-delivery {
          .address-form__content {
            margin-bottom: 0;
          }
        }
      }
      &.checkout__content {
        #delivery-options {
          .ship-method-group {
            margin-bottom: 16px;
          }
          .delivery-title {
            margin-bottom: 24px;
          }
          .shipmethod-home-delivery {
            padding: 16px 15px 15px;
          }
        }
      }
    }
    .column.right {
      &.checkout__sidebar {
        .order-summary-panel {
          .order-summary__content {
            .tax {
              display: none;
            }
          }
        }
        .links-panel {
          background-color: $color-gray-background;
        }
      }
    }
    &.shipping {
      .checkout-header {
        display: none;
      }
    }
  }
  .ship-method-group-label {
    .click_and_collect_option {
      margin-#{$rdirection}: 0;
    }
    .home_delivery_option,
    .click_and_collect_option {
      display: block;
      padding-#{$ldirection}: 0;
      text-align: center;
      &::after {
        #{$rdirection}: auto;
        background-position: center;
      }
    }
  }
  #delivery-options {
    .ship-method-group-label {
      input[type='radio'] {
        position: absolute;
        width: 100%;
        &::after {
          bottom: 20px;
          width: 100%;
        }
      }
    }
  }
  &#payment {
    .checkout-title {
      .shipping-panel__title {
        font-size: 36px;
      }
    }
    .column.right {
      &.checkout__sidebar {
        #shipping-panel {
          #review-address-info {
            .transaction-panel-details {
              .transaction-item.transaction-details-content {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
